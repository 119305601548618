exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-concessionarios-js": () => import("./../../../src/pages/concessionarios.js" /* webpackChunkName: "component---src-pages-concessionarios-js" */),
  "component---src-pages-configurador-js": () => import("./../../../src/pages/configurador.js" /* webpackChunkName: "component---src-pages-configurador-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modelos-kia-js": () => import("./../../../src/pages/modelos-kia.js" /* webpackChunkName: "component---src-pages-modelos-kia-js" */),
  "component---src-pages-obrigado-pelo-seu-pedido-js": () => import("./../../../src/pages/obrigado-pelo-seu-pedido.js" /* webpackChunkName: "component---src-pages-obrigado-pelo-seu-pedido-js" */),
  "component---src-pages-oportunidades-js": () => import("./../../../src/pages/oportunidades.js" /* webpackChunkName: "component---src-pages-oportunidades-js" */),
  "component---src-pages-pedido-informacao-js": () => import("./../../../src/pages/pedido-informacao.js" /* webpackChunkName: "component---src-pages-pedido-informacao-js" */),
  "component---src-pages-proposta-financiamento-js": () => import("./../../../src/pages/proposta-financiamento.js" /* webpackChunkName: "component---src-pages-proposta-financiamento-js" */),
  "component---src-pages-suv-js": () => import("./../../../src/pages/suv.js" /* webpackChunkName: "component---src-pages-suv-js" */),
  "component---src-pages-test-drive-js": () => import("./../../../src/pages/test-drive.js" /* webpackChunkName: "component---src-pages-test-drive-js" */),
  "component---src-templates-configurador-detail-js": () => import("./../../../src/templates/configurador-detail.js" /* webpackChunkName: "component---src-templates-configurador-detail-js" */),
  "component---src-templates-informacao-detail-js": () => import("./../../../src/templates/informacao-detail.js" /* webpackChunkName: "component---src-templates-informacao-detail-js" */),
  "component---src-templates-modelo-detail-js": () => import("./../../../src/templates/modelo-detail.js" /* webpackChunkName: "component---src-templates-modelo-detail-js" */),
  "component---src-templates-oportunidade-detail-js": () => import("./../../../src/templates/oportunidade-detail.js" /* webpackChunkName: "component---src-templates-oportunidade-detail-js" */),
  "component---src-templates-proposta-financiamento-detail-js": () => import("./../../../src/templates/proposta-financiamento-detail.js" /* webpackChunkName: "component---src-templates-proposta-financiamento-detail-js" */),
  "component---src-templates-test-drive-detail-js": () => import("./../../../src/templates/test-drive-detail.js" /* webpackChunkName: "component---src-templates-test-drive-detail-js" */)
}

